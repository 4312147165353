import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../../Common/Components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import Constants from "../../../../Utils/Constants";
import Context from "../../../../store/Contex";
import axiosInstance from "../../../../setup/axios";
import style from "../../style.module.css";
import Notify from "../../../../Helpers/Toast";
import AuthService from "../../../../setup/AuthServices";

const EmailForm = () => {
  const { state, dispatch } = useContext(Context);
  const navigate = useNavigate();
  const params = useParams();

  const [formState, setFormState] = useState({
    isRequesting: false,
    loadText: "",
  });

  useEffect(() => {
    function sendEmailToken() {
      setFormState({
        ...formState,
        isRequesting: true,
        loadText: "Checking Email Verification",
      });
      axiosInstance
        .post(`${Constants.API_BASE_URL}/auth/user/email/token`)
        .then((res) => {
          setFormState({ ...formState, isRequesting: false, loadText: "" });
          Notify("top-center", "Email verification sent to your email");
        })
        .catch((e) => {
          setFormState({ ...formState, isRequesting: false, loadText: "" });
          console.log(e)
          Notify(
            "top-center",
            "Sorry, something went wrong, try again",
            "error",
          );
        });
    }

    function validateEmailToken() {
      setFormState({
        ...formState,
        isRequesting: true,
        loadText: "Verifying your Email",
      });
      let data = {
        verificationToken: params.token,
      };
      axiosInstance
        .post(
          `${Constants.API_BASE_URL}/auth/verify/email/token`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          let userInfo = state.userInfo;
          userInfo = {
            ...userInfo,
            emailIdentity: { ...userInfo.emailIdentity,  emailVerified: true },
            user: {
              ...userInfo.user,

            },
          };
          dispatch({ type: "USER_INFO", payload: userInfo });
          dispatch({ type: "LOGIN" });
          sessionStorage.setItem("isLoggedIn", "true");
          dispatch({
            type: "TOKENS",
            payload: {
              token: res.data?.token,
              refreshToken: res.data?.refreshToken,
            },
          });

          setFormState({ ...formState, isRequesting: false });
          navigate("/");

          Notify("top-center", "Your Email is verified 🙂");

        })
        .catch(() => {
          setFormState({ ...formState, isRequesting: false });
          Notify(
            "top-center",
            "Sorry, something went wrong, try again",
            "error",
          );
        });
    }

    params.email ? sendEmailToken() : validateEmailToken();

    // eslint-disable-next-line
  }, []);

  const resendEmailCode = () => {
    setFormState({
      ...formState,
      isRequesting: true,
      loadText: "Sending new verification link",
    });
    axiosInstance
      .post(
        `${Constants.API_BASE_URL}/auth/user/email/token`,
        AuthService.getAxiosHeaders(),
      )
      .then(() => {
        setFormState({
          ...formState,
          loadText: "Resending email verification token",
        });
        Notify("top-center", "New Verification link sent", "success");
        setTimeout(() => {
          setFormState({ ...formState, isRequesting: false, loadText: "" });
        }, 200);
      })
      .catch((err) => {
        setFormState({ ...formState, loadText: "Something went wrong" });
        Notify("top-center", "Sorry, something went wrong, try again", "error");
        setTimeout(() => {
          setFormState({ ...formState, isRequesting: false, loadText: "" });
        }, 200);
      });
  };
  const link = `
            https://media.istockphoto.com/id/1388787982/vector/open-envelope-with-check-document-icon-in-excellent-flat-design-vector-illustration-eps10.jpg?s=612x612&w=0&k=20&c=BWuoArx53bCbmWh0dg1fwzSMYgu5te26HBP_iNgSbxo=
        `;
  return (
    <form
      action=""
      className={`w-full h-full overflow-y-auto px-1 ${style.loginForm}`}
    >
      {formState.isRequesting && <Loading loadText={formState.loadText} />}

      <div className="flex flex-col a-i-s sm:mt-16 mb-8">
        <h1 className={`text-3xl md:text-4xl text-left font-bold`}>
          Account Activation
        </h1>
        <h1 className={`text-lg text-blue`}>Email Verification</h1>
        <br />
        <p className={`font-normal text-[15px] text-left text-gray-600 mt-3`}>
          Verify the Email you provided{" "}
          <b className="text-theme font-semibold">{params.email ?? ""}</b>
        </p>
        <div className="mt-2 text-[#0048ff] text-left">
          Note: If you don't find the mail in your inbox, check your spam mails.
        </div>
      </div>

      <div className="flex flex-col j-c-c cus-sm:w-[400px] mb-20">
        <img
          src={link}
          alt=""
          className="rounded-full w-[130px] cus-sm:w-[200px]"
        />
        <p className="text-gray-600 mt-3">
          A verification link has been sent to the email you provided. Please
          check and verify your account
        </p>
      </div>

      <div className="flex mt-5">
        <div className="flex w-[30px] h-[30px] border mr-3 j-c-c rounded-full text-white bg-theme">
          <i className="fa fa-info text-sm"></i>
        </div>
        <p>
          Didn't get the verification link ?{" "}
          <b className="text-theme" onClick={resendEmailCode}>
            Resend
          </b>
        </p>
      </div>

      <div className="w-full flex mt-5">
        <p className="text-gray-500">
          Already have an Account?{" "}
          <b
            className="font-normal text-blue text-[0.85rem] cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Sign in here
          </b>
        </p>
      </div>
    </form>
  );
};

export default EmailForm;