import React from "react";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import { dotSpinner } from "ldrs";
import dayjs from "dayjs";
import downarrow from "../../../../Common/Assets/Images/downarrow.png";
import uparrow from "../../../../Common/Assets/Images/uparrow.png";
import { FolderOpen } from "solar-icon-set/folders";
import { useNavigate } from "react-router-dom";

function RecentOrders() {
  dotSpinner.register();
  const navigate = useNavigate();

  const { isLoading, data } = useQuery({
    queryKey: ["orders"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/users/orders?limit=5`,
      );
      return response?.data?.data;
    },
  });

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[250px] min-w-[250px] ">
        <div className="mb-4">
          <l-dot-spinner size="60" speed="0.9" color="black"></l-dot-spinner>
        </div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="text-xl font-semibold text-left">Recent Orders</div>
      {data && data.length > 0 ? (
        data.map((order: any) => (
          <aside
            key={order?.id}
            onClick={() => {
              if (order?.type === "sell") {
                navigate("/sell-details", { state: { order } });
              } else {
                navigate("/buy-details", { state: { order } });
              }
            }}
            className="flex justify-between w-full my-3 cursor-pointer"
          >
            <div className="flex items-center mr-6">
              <div className="flex justify-center rounded-xl">
                <div className="relative w-[35px] h-[35px]">
                  <img
                    src={order?.assetEntity?.icon}
                    alt=""
                    className="w-8 h-8"
                  />
                  {order.type === "sell" ? (
                    <img
                      src={downarrow}
                      alt=""
                      className="absolute w-4 h-4 right-0 bottom-0"
                    />
                  ) : (
                    <img
                      src={uparrow}
                      alt=""
                      className="absolute w-4 h-4 right-0 bottom-0"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col ml-2 items-start ">
                <div className="text-[0.9rem] text-left">{order?.asset}</div>
                <small className="text-gray-400">
                  {dayjs(order?.createdAt).format("MMM D, YYYY")}
                </small>
              </div>
            </div>
            <div className="flex flex-col items-end text-right">
              <h1
                className={`${
                  order.type === "buy" ? "text-theme" : "text-red-500"
                } text-sm`}
              >
                {order?.amount}
              </h1>
            </div>
          </aside>
        ))
      ) : (
        <div className="flex justify-center w-full h-32 flex-col items-center">
          <FolderOpen
            color="#040406"
            size={40}
            iconStyle="Bold"
            className="mb-5"
          />
          <p className="text-gray-500">No Orders Yet ...</p>
        </div>
      )}
    </div>
  );
}

export default RecentOrders;
