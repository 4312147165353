import React, { useState } from "react";
import notifs from "../../../../Pages/Main/Components/SideBar/Assets/Icons/Notifications.png";
import prev from "../../../../Common/Assets/Icons/prev.png";
import next from "../../../../Common/Assets/Icons/next.png";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import dayjs from "dayjs";
import { dotSpinner } from "ldrs";

type Props = {
  isOn?: boolean;
  hasNewNotifications?: boolean;
  setHasNewNotifications: (val: boolean) => void;
};

function Notifications({
  isOn,
  hasNewNotifications,
  setHasNewNotifications,
}: Props) {
  dotSpinner.register();

  const [page, setPage] = useState(0);
  const limit = 5;

  const fetchNotifications = async (page = 0) => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/notifications?limit=${limit}&skip=${page * limit}`,
    );
    return response.data;
  };

  const { data, isPlaceholderData, isLoading } = useQuery({
    queryKey: ["projects", page],
    queryFn: () => fetchNotifications(page),
  });
  const totalPages = data ? Math.ceil(data?.total / limit) : 0;

  return (
    <div
      className={`absolute rounded-[14px] w-[300px] h-auto flex flex-col mt-20 ${
        isOn ? "animatefromrigh" : "reverseright"
      } mr-1`}
    >
      <div className="w-full h-[70px] bg-blue rounded-t-[14px] flex px-5">
        <h1 className="text-white font-semibold">Notifications</h1>
      </div>
      {isLoading ? (
        <div className="flex flex-col justify-center rounded-b-[14px] overflow-hidden items-center min-h-[200px] w-full p-6 bg-white">
          <div className="mb-4">
            <l-dot-spinner size="80" speed="0.9" color="black"></l-dot-spinner>
          </div>
          <div>
            <h2 className="p-8">Loading...</h2>
          </div>
        </div>
      ) : data && data?.data?.length > 0 ? (
        <div className="w-full max-h-[450px] rounded-b-[14px] overflow-hidden bg-white flex flex-col">
          <div className="w-full grow flex flex-col overflow-auto">
            {data?.data?.map((val: any, idx: number) => (
              <div
                key={idx}
                className="w-full h-[80px] flex flex-col px-6 py-3"
              >
                <div className="flex justify-between w-full">
                  <div className="text-black font-semibold text-left whitespace-nowrap">
                    {val?.title}
                  </div>
                  <div className="text-gray-400 text-[10px] whitespace-nowrap">
                    {dayjs(val?.createdAt).format("MMM D, YYYY h:mm A")}
                  </div>
                </div>
                <div className="text-gray-700 text-[12px] text-left text-pretty">
                  {val?.message}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full flex justify-between px-3 py-2">
            <div>
              <button
                className={`h-3 w-4 ${page === 0 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                disabled={page === 0}
                onClick={() => setPage((old) => Math.max(old - 1, 0))}
              >
                <img src={prev} alt="Previous" />
              </button>
            </div>
            <div>
              <span className="text-[12px]">
                Page {page + 1} of {totalPages}
              </span>
            </div>
            <div>
              <button
                className={`h-3 w-4 ${!data?.hasMore ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                onClick={() => {
                  if (!isPlaceholderData && data.hasMore) {
                    setPage((old) => old + 1);
                  }
                }}
                disabled={isPlaceholderData || !data?.hasMore}
              >
                <img src={next} alt="Next" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full grow flex flex-col justify-center items-center rounded-[14px]">
          <img src={notifs} alt="" className="mr-2 h-10 w-10" />
          <h1 className="text-black font-semibold">No Notifications</h1>
        </div>
      )}
    </div>
  );
}

export default Notifications;
