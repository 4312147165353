import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "../store/Contex";
import axios from "axios";
import Constants from "../Utils/Constants";

export const passPages = [
  "forgot-password",
  "email-verification/check",
  "email-verification/code",
  "phone-verification",
  "register",
  "login",
  "home",
];

export const refreshPassPages = ["register", "login", "home"];
const RouteMiddleware = () => {
  const { state, dispatch } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();

  const isLogin = JSON.parse(sessionStorage.getItem("isLoggedIn") || "false");
  const tokens = `${localStorage.getItem("tokens") ?? "{}"}`;
  const access_token = tokens ? JSON.parse(tokens)?.token : null;

  const noAuth = passPages.find((link) => location.pathname.includes(link));



  try {
    if (!noAuth) {
      // if(!user?.emailIdentity?.emailVerified) {
      //     window.location.href = `/email-verification/check/${user?.emailIdentity?.email}`
      // }

      // if(!user?.phoneIdentity?.phoneVerified) {
      //     window.location.href = "/phone-verification"
      // }

      if (!isLogin) {
        window.location.href = "/login";
      }

      if (access_token === null || access_token?.length === 0) {
        window.location.href = "/login";
      }
    }
  } catch (e) {
    console.log(e)
  }

  const refreshToken = async () => {
    sessionStorage.setItem("curTime", "2000");
    // console.log(state.tokens.refreshToken)
    try {
      const response = await axios.post(
        `${Constants.API_BASE_URL}/auth/refresh/token`,
        {
          refreshToken: state.tokens.refreshToken,
        },
      );
      dispatch({
        type: "TOKENS",
        payload: {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      });
      sessionStorage.setItem(
        "tokens",
        JSON.stringify({
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        }),
      );
      // console.log(response)
    } catch (e: any) {
      // console.log(e)
      try {
        if (e.response.status === 401) {
          navigate("/login", { replace: true });
        }
      } catch (e) {
        return false;
      }
    }
  };

  const noAuthRefresh = refreshPassPages.find((link) =>
    location.pathname.includes(link),
  );
  useEffect(() => {
    // console.log(state.tokens.token)
    const tokenRefreshTimer = setInterval(() => {
      if (!noAuthRefresh) {
        let curTime = parseInt(sessionStorage.getItem("curTime") ?? "2000");
        if (curTime > 0) {
          if (curTime < 1100000) {
            let newTime = curTime + 1500;
            sessionStorage.setItem("curTime", newTime.toString());
          } else {
            refreshToken();
          }
        } else {
          sessionStorage.setItem("curTime", "2000");
        }
      }
    }, 2 * 1000);

    return () => {
      clearInterval(tokenRefreshTimer);
    };
    // eslint-disable-next-line
  }, [state.tokens]);

  // useEffect(() => {
  //     console.log(state)
  //     if(state.isLoggedIn){
  //         const tokenRefreshInterval = setInterval(async () => {
  //             let newTokens = await refreshAccessToken();
  //             if(newTokens) {
  //                 clearInterval(tokenRefreshInterval)
  //                 dispatch({type: 'TOKENS', payload: newTokens})
  //             }
  //         }, 600000);

  //         return () => clearInterval(tokenRefreshInterval);
  //     }
  //     console.log('djkfs')
  //     // eslint-disable-next-line
  // }, [state.tokens]);

  return <></>;
};

export default RouteMiddleware;
