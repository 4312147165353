import React, { useEffect, useState } from "react";
import pag from "./pag";
import { useParams } from "react-router-dom";

type props = {
  total: number;
  setPage: (page: number) => void;
  isRequesting: boolean;
};

function Pagination({ total, setPage, isRequesting }: props) {
  const [current, setCurrent] = useState<number>(1);

  const handlePageChange = (page: number) => {
    setPage(page);
    if (!isRequesting) {
      setCurrent(page);
    }
  };
  const params = useParams();
  let paramsPage = parseInt(params.page || "1");
  useEffect(() => {
    setCurrent(paramsPage);
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className={`flex j-c-b w-full py-5`}>
      <div
        className="flex min-w-[90px] cursor-pointer"
        onClick={() => handlePageChange(current - 1)}
      >
        {current - 1 !== 0 && (
          <>
            <i className="fa fa-arrow-left mr-2"></i>
            <p>Previous</p>
          </>
        )}
      </div>

      <div className="flex">
        {current - 3 > 0 && (
          <div
            onClick={() => handlePageChange(current - 3)}
            className="flex mr-1 cursor-pointer hover:text-blue"
          >
            <i className="fa fa-chevron-left"></i>
            <i className="fa fa-chevron-left"></i>
          </div>
        )}

        {pag(total).map((val) => {
          return (
            <div
              style={{
                width: "2rem",
                height: "2rem",
                display: `${Math.abs(+current - val) < 3 || val < current + 3 || +current === val ? "flex" : "none"}`,
              }}
              onClick={() => handlePageChange(val)}
              key={val}
              className={`w-8 h-8 a-i-c j-c-c mx-2 cursor-pointer ${current === val ? "text-white bg-blue" : "text-gray-500 bg-gray-100"}`}
            >
              {val}
            </div>
          );
        })}

{current + 3 <= pag(total)[pag(total).length - 1] && (
          <div
            onClick={() => handlePageChange(current + 3)}
            className="flex ml-1 cursor-pointer hover:text-blue"
          >
            <i className="fa fa-chevron-right"></i>
            <i className="fa fa-chevron-right"></i>
          </div>
        )}
      </div>

      <div
        className="flex min-w-[90px] cursor-pointer"
        onClick={() => handlePageChange(current + 1)}
      >
        {current + 1 <= pag(total)[pag(total).length - 1] && (
          <>
            <p>Next</p>
            <i className="fa fa-arrow-right ml-2"></i>
          </>
        )}
      </div>
    </div>
  );
}

export default Pagination;
