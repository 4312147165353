import React from "react";

function MarketShama() {
  const data = [1, 1, 1, 1];

  return (
    <>
      {data.map((val, idx) => {
        return (
          <div
            key={idx}
            className="w-[200px] h-[55px] rounded-lg bg-gray-100 p-[8px] flex j-c-b mr-2"
          >
            <div className="flex h-full">
              <div className="w-10 rounded-lg h-full bg-gray-200 shema"></div>
              <div className="flex flex-col items-start ml-1 h-full py-1">
                <div className="w-10 h-2 rounded-md bg-gray-200 shema"></div>
                <div className="w-14 h-2 rounded-md bg-gray-200 shema mt-2"></div>
              </div>
            </div>
            <div className="flex flex-col items-end ml-1 h-full py-1">
              <div className="w-10 h-2 rounded-md bg-gray-200 shema"></div>
              <div className="w-6 h-2 rounded-md bg-gray-200 shema mt-2"></div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default MarketShama;
