import React, { CSSProperties } from "react";
import { marketType } from "../../Dashboard";

type MarketProp = {
  className?: string;
  style?: CSSProperties;
  data: marketType[];
};

function Market({ className, style, data }: MarketProp) {
  return (
    <>
      {data.map((item, idx) => (
        <div
          key={idx}
          style={style}
          className={`flex ${className} hover:bg-gray-100 cursor-pointer`}
        >
          <div className="flex mr-6">
            <div
              className={`flex j-c-c rounded-2xl `}
              style={{ width: "30px", height: "30px" }}
            >
              <img src={item?.icon} alt="" />
            </div>

            <div className="flex flex-col a-i-s ml-2">
              <h1 className="text-[0.9rem]">{item?.code}</h1>
              <small className="text-gray-400 text-[0.8rem]">{item.name}</small>
            </div>
          </div>
          <div className="flex flex-col items-end text-right">
            <h1 className="text-[0.9rem]">
              {parseFloat(item.price).toFixed(2)}
            </h1>
            <div className="flex text-red-600 text-[0.8rem]">
              <i className="fa fa-chevron-down"></i>
              <small>{parseFloat(item.change).toFixed(5)}</small>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Market;
